<template>
  <svg role="img" :aria-label="props.label" :class="props.class || ''">
    <use :href="iconHref" />
  </svg>
</template>

<script setup lang="ts">
type Props = {
  name: string;
  class?: string;
  label?: string;
  path?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const props = withDefaults(defineProps<Props>(), {
  label: "Svg Icon",
  class: "",
  path: "/svgs/common.svg",
});

const iconHref = computed(() => `${props.path || "/svgs/common.svg"}#${props.name}`);
</script>
